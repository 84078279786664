






























































































































































































































































































































































































































































































.process-button {
  margin-top: 20px;
}

.ticket-shop .checkout-view .checkout__wizard .checkout-form__container .checkout-form__group.privacyStatement {
  display: block;
}

.ticket-shop {
  .checkout-view {
    .checkout__slot-selection {
      width: 100%;

      h5 {
        margin-top: 0;
        margin-bottom: 0;
      }

      .ticketModal__itemSlot {
        width: 100%;
        //@media screen and (min-width: 680px) {
        //  max-width: 70%;
        //}
        //@media screen and (min-width: 1280px) {
        //  max-width: 50%;
        //}
        margin-bottom: 0;
        padding: 15px 30px 15px 0;
      }
    }

    .client-agb-privacy {
      .inline-link:hover,
      a:hover {
        text-decoration: none;
      }
    }
  }

  .inline-link:hover {
    text-decoration: none;
  }

  .registration__invitationInfo {
    display: flex;
    flex-wrap: nowrap;
    padding: 20px;
    margin-bottom: 20px;
    background-color: var(--color-grey-background);

    .registration__invitationCompany {
      white-space: nowrap;
    }

    .registration__invitationLogoMobile {
      min-width: 140px;
      max-width: 240px;
      display: block;
      margin-bottom: 25px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .registration__invitationLogoDesktop {
      min-width: 140px;
      max-width: 240px;
      display: none;

      img {
        width: 100%;
        height: auto;
      }
    }

    @media screen and (min-width: 560px) {
      .registration__invitationLogoMobile {
        display: none;
      }
      .registration__invitationLogoDesktop {
        display: block;
      }
    }
  }

  .noLinebreak {
    white-space: nowrap;
  }
}

.ticket-generation {
  text-align: center;
}

.ticket-download-item {
  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .button {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
