




























.ticket__description {
  .ticket__descriptionTitle {
    display: flex;
    align-items: center;
  }

  .ticket__vvsIcon {
    margin-left: 0.3em;
    min-width: 24px;
  }

  .ticket__sold-out {
    margin-bottom: 7px;
  }
}

