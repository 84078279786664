





































































































































































































.ticket__descriptionTitle {
  display: flex;

  svg {
    min-width: 24px;
  }
}
