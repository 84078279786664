












































































































































































































































































.cart__headline {
  font-weight: bold;
  margin-top: 40px;
}

.ticket__mobileAmountChange {
  display: flex;
}

.privacyStatement__descriptionTitle {
  margin-top:0.3em;
  margin-bottom: 0.8em;
}

.ticket {
  .ticket__interaction {
    display: none;
  }
  &.ticket--sum {
    &.space-above {
      margin-top: 80px
    }
  }
}

.cart-view .grid{
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.cart-view .ticketCode {
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

