













































































.ticket-code-view {
  .ticketCode__box {
    margin: 0 0 20px 0;
  }
}
