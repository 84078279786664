








































































.personal-use-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.disabled {
    opacity: 0.5;
  }

  &:last-of-type {
    margin-bottom: 40px;
  }

  .personal-use-item--description {
    flex: 5;
    margin-right: 40px;
  }

  .form__group {
    margin-bottom: 0;
  }

  .personal-use-item--quota {
    flex: 3;
    margin-right: 40px;

    .ticket__quotaTitle {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 10px;
      font-family: var(--font-bold);
    }
  }
}
