


























.public-transport-selection {
  margin-bottom: 40px;

  /deep/ button {
    margin-right: 0;
  }
}

.personal-use-modal {
  h1 {
    font-family: var(--font-regular);
    font-weight: var(--font-regular-weight);
  }
}

.personal-use-modal {
  button {
    width: auto;
    padding: 5px 45px;
  }
}

/deep/ .modal__overlayClose {
  display: none;
}
