












.spinner {
  width: 60px;

  &.spinner--absolute {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.spinner--white {
    & > div {
      background: white;
    }
  }
}
