
















































































































































































































































































































































.form__field--error {
  input[type=email],
  input[type=checkbox],
  input[type=text],
  input[type=password],
  input[type=radio],
  input,
  select,
  textarea,
  .checkbox-label::after {
    border: 1px solid #C62828 !important;
  }
}
