
































































































































select {
  border: 1px solid #aaa !important;
  height: 40px;
  min-width: 250px;
}

.ticket:first-of-type {
  border-top: 1px solid var(--color-grey-border);
  margin-top: 50px;
}

.ticket:last-of-type {
  margin-bottom: 50px;
}

.public-transport-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .public-transport-item {
    flex: 33%;

    &.public-transport-item--use-public-trans {
      .checkout-form__field {
        &.checkout-form__field--radio {
          justify-content: flex-end;
        }
      }
    }

    &.public-transport-item--quota {
      margin-right: 40px;
    }

    &.public-transport-item--date-select {
      margin-left: 30%;
      .form__selectWrapper {
        &.disabled {
          &:after {
            opacity: 0.5;
          }
        }
      }
    }

    &.public-transport-item--name-input {
      margin-left: 30%;
    }

    &.public-transport-item--entire-event {
      margin-right: 15px;
    }
  }

  &.isSubConfig {
    margin-left: 2rem;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &.unselectable {
    color: grey;
  }

  .form__group {
    margin-bottom: 0;
  }

  .ticket__descriptionTitle {
    &.isSubConfig {
      font-size: 1.2rem;
    }
  }

  .public-transport-item--quota {
    display: flex;
    flex-direction: column;

    .ticket__quotaTitle {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 10px;
      font-family: var(--font-bold);
    }
  }
}
