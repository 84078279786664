































































































































































































































































































































































































































































































































































































































































.consent-modal-buttons {
  display: flex;
  justify-content: space-between;
  .button {
    max-width: 200px;
    display: inline-block;
    margin: 0 0 5px;
  }
}
@media (max-width: 729px) {
  .consent-modal-buttons {
    flex-direction: column;
    align-items: center;
    .button {
      margin-bottom: 10px !important;
      margin-top: 10px !important;
      height: 60px;
      word-wrap: break-word;
      font-size: 1.2rem;
    }
  }
}

.checkout-form__container {
  position: relative;
}

button.wizard-footer-right {
  width: auto;
}

.privacyModal {
  font-family: var(--font-light);
  line-height: 22px;
  font-size: 15px;

  ul {
    padding-left: 18px;
    padding-bottom: 20px;

    ul {
      padding-bottom: 0;
    }
  }
}

.display-none {
  display: none;
}

.progress-container {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
}

.progress-bar {
  height: 30px;
  background-color: #4caf50;
  text-align: center;
  line-height: 30px;
  color: white;
}
