










.info-button-container {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  height: 27px;

  .info-button-text {
    visibility: hidden;
    width: 300px;
    background-color: #979797;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 6px;
    position: absolute;
    z-index: 1;
    bottom: 35px;
    left: 50%;
    font-size: 14px;
    margin-left: -156px; /* = (width + padding(s)) / 2 */
    &:after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -10px;
      border: 10px solid rgba(0, 0, 0, 0);
      border-top-color: #979797;
    }
  }
}

.info-button-container:hover .info-button-text {
  visibility: visible;
}
