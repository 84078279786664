










































.ticket-download-wrapper {
  padding-top: 40px;
}

.ticket-generation {
  text-align: center;
}

.ticket-download-item {
  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .button {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
